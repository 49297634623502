<template>
  <div class="google-property-selector">
    <template v-if="accountSummary.length > 0">
      <div>
        Select a Google Analytics Property or View to view in reporting.
      </div>
      <div
        v-if="selectedProperty"
        class="mt-3"
      >
        Current Selection: {{ selectedProperty.name }}
      </div>
      <validation-observer ref="textInput">
        <validation-provider
          :rules="{
            required
          }"
          mode="eager"
        >
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            nudge-top="-5px"
            :offset-y="true"
          >
            <template #activator="{ on, attrs }">
              <v-btn
                class="elevation-0 outlined-btn px-12 mt-5"
                height="34px"
                plain
                v-bind="attrs"
                v-on="on"
                @click="$emit('click')"
              >
                Select Property
              </v-btn>
            </template>
            <v-card
              class="google-property-menu"
            >
              <v-row style="height: 100%;margin: 0;">
                <v-col class="property-col">
                  <div class="property-col-header">
                    Analytics Accounts
                  </div>

                  <v-list>
                    <v-list-item
                      v-for="account in accounts"
                      :key="'account-' + account.id"
                      :class="{'selected': accountId === account.id}"
                      @click="onSelectAccount(account)"
                    >
                      <div>
                        <div class="name">
                          {{ account.name }}
                        </div>
                        <div class="id-container">
                          {{ account.id }}
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col class="property-col">
                  <div class="property-col-header">
                    Properties & Apps
                  </div>

                  <v-list>
                    <v-list-item
                      v-for="property in properties"
                      :key="'property-' + property.id"
                      :class="{'selected': propertyId === property.id}"
                      @click="onSelectProperty(property)"
                    >
                      <div>
                        <div class="name">
                          {{ property.name }}
                        </div>
                        <div class="id-container">
                          {{ property.id }}
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-col>
                <v-col class="property-col">
                  <div class="property-col-header">
                    Views
                  </div>

                  <v-list v-if="views">
                    <v-list-item
                      v-for="view in views"
                      :key="'view-' + view.id"
                      :class="{'selected': viewId === view.id}"
                      @click="onSelectView(view)"
                    >
                      <div>
                        <div class="name">
                          {{ view.name }}
                        </div>
                        <div class="id-container">
                          {{ view.id }}
                        </div>
                      </div>
                    </v-list-item>
                  </v-list>
                </v-col>
              </v-row>
            </v-card>
          </v-menu>
        </validation-provider>
      </validation-observer>
    </template>
    <template v-else>
      <v-alert
        type="error"
        class="text-center"
      >
        You do not have access to any accounts or properties. Please contact the Google Analytics administrator to gain access.
      </v-alert>
    </template>
  </div>
</template>

<script>
export default {
  name: "GooglePropertySelector",
  props: {
    accountSummary: {
      type: Array,
      default: () => [],
    },
    value: {
      type: Number,
      default: 0,
    },
    required: {
      type: [Boolean, Object],
      default: false,
    },
  },
  data() {
    return {
      accountId: null,
      propertyId: null,
      viewId: null,
      isGA4: true,
      menu: false,
      selectedProperty: null,
    };
  },
  computed: {
    accounts() {
      return [...this.accountSummary].sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    properties() {
      let properties = [];
      this.accountSummary.forEach((account) => {
        if (parseInt(this.accountId) === parseInt(account.id)) {
          properties = [...account.properties];
        }
      });

      return properties.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
    views() {
      let views = [];

      if (!this.propertyId) {
        return views;
      }

      this.accountSummary.forEach((account) => {
        if (parseInt(this.accountId) === parseInt(account.id)) {
          account.properties.forEach(property => {
            if (this.propertyId === property.id && !property.isGA4) {
              views = [...property.profiles];
            }
          })
        }
      });

      return views.sort((a, b) => {
        return a.name.localeCompare(b.name);
      });
    },
  },
  created() {
    if (this.value === 0) {
      this.accountId = this.accountSummary.length > 0
        ? this.accountSummary[0]?.id
        : null;
      return;
    }

    this.accountSummary.forEach((account) => {
      if (!account.properties?.length) {
        return;
      }

      account.properties.forEach((property) => {
        if (!property.isGA4) {
          // If the property is UA, we need to check the profiles

          property.profiles.forEach((profile) => {
            if (profile.id === this.value) {
              this.accountId = account.id;
              this.propertyId = property.id;
              this.isGA4 = false;
              this.onSelectView(profile);
            }
          });

          return;
        }

        if (property.id === this.value) {
          this.accountId = account.id;
          this.isGA4 = true;
          this.onSelectProperty(property);
        }
      });
    });
  },
  methods: {
    onSelectAccount(account) {
      if (this.accountId === account.id) {
        return;
      }

      this.accountId = account.id;
      this.propertyId = null;
      this.viewId = null;
      this.selectedProperty = null;

      this.$emit('select', null);
    },
    onSelectProperty(property) {
      if (this.propertyId === property.id) {
        return;
      }

      this.propertyId = property.id;
      this.viewId = null;

      if (property.isGA4) {
        this.$emit('select', property);
        this.menu = false;
        this.selectedProperty = {...property};
      } else {
        this.$emit('select', null);
      }
    },
    onSelectView(view) {
      if (this.viewId === view.id) {
        return;
      }

      this.viewId = view.id;

      this.$emit('select', view);
      this.menu = false;
      this.selectedProperty = {...view};

      let property = this.properties.find((prop) => {
        return prop.id === this.propertyId;
      });

      this.selectedProperty.name = property?.name + ' > ' + view.name;
    }
  }
};
</script>

<style lang="scss" scoped>
.google-property-menu {
  height: 432px;
  max-height: 432px;
  width: 832px;

  .property-col {
    border-right: 3px solid #dadce0;
    height: 100%;
    padding: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-overflow: ellipsis;

    .v-list-item {
      padding-top: 5px;
      padding-bottom: 5px;
    }

    .name {
      display: block;
    }
    .id-container {
      font-size: 10px;
    }

    .selected {
      background: #f1f3f4;

      .name {
        font-weight: bold;
      }
    }
  }
  .property-col-header {
    border-bottom: 1px solid #dadce0;
    font-size: 12px;
    padding: 10px 20px;
  }
}
</style>
