<template>
  <v-container class="mt-5 mb-15 px-10" fluid>
    <PageHeader>
      <template #header>
        <img
          src="@/assets/icons/google-analytics.svg"
          alt="google analytic icon"
          height="32"
        >
        {{ installed ? 'Update' : 'Install' }} Google Analytics Plugin
      </template>
      <template #subheader>
        Fill out these information in order to install the Google Analytics plugin.
      </template>
    </PageHeader>

    <div
      v-if="checkedAuthentication"
      class="mt-10"
    >
      <v-row>
        <v-col>
          <v-btn
            v-if="!isAuthenticated"
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="authenticateWithGoogle"
          >
            Authenticate with Google
          </v-btn>
          <template v-if="isAuthenticated">
            <p
              v-if="userEmail"
            >
              Authenticated as <strong>{{ userEmail }}</strong>!
            </p>
            <p v-else>
              Authenticated!
            </p>
            <v-btn
              class="custom-button custom-button--blue px-13"
              height="34px"
              depressed
              :block="$vuetify.breakpoint.xsOnly"
              @click="authenticateWithGoogle"
            >
              Click to Re-Authenticate
            </v-btn>
          </template>
          <v-alert
            class="mt-3"
            style="font-size: 14px;"
            outlined
            color="teal"
            text
          >
            When authenticating with Google, you may see a list of checkboxes to confirm the permissions you want to allow.
            Please check the box to allow access to Google Analytics data for this plugin to work correctly.
          </v-alert>
        </v-col>
      </v-row>
      <v-row
        v-if="accountSummary !== null"
        class="mt-12"
      >
        <v-col cols="12">
          <validation-observer>
            <GooglePropertySelector
              :account-summary="accountSummary"
              :value="pluginConfiguration.viewId"
              :is-g-a-4="pluginConfiguration.isGA4"
              @select="onSelectGoogleProperty"
            />
          </validation-observer>
        </v-col>

        <v-col cols="12" class="d-flex justify-end">
          <v-btn
            v-if="installed"
            class="custom-button custom-button--red-text px-8 mr-4"
            height="34px"
            depressed
            @click="deactivateDialog = true;"
          >
            Deactivate
          </v-btn>

          <v-btn
            class="custom-button custom-button--blue px-13"
            height="34px"
            depressed
            :block="$vuetify.breakpoint.xsOnly"
            @click="updatePlugin(true)"
          >
            {{ plugin ? "Update" : "Install" }}
          </v-btn>
        </v-col>
      </v-row>
    </div>

    <DeactivateConfirmDialog
      v-if="deactivateDialog"
      :id="id"
      :plugin="plugin"
      @dismiss="deactivateDialog = false;"
    />
  </v-container>
</template>

<script>
import PageHeader from "@/sharedComponents/PageHeader";
import GooglePropertySelector from "@/views/Plugins/components/GooglePropertySelector";
import DeactivateConfirmDialog from "./components/DeactivateConfirmDialog";

export default {
  name: "AddPluginGoogleAnalytics",
  metaInfo: {
    title: 'Install Google Analytics'
  },
  components: {
    PageHeader,
    GooglePropertySelector,
    DeactivateConfirmDialog,
  },
  props: {
    id: {
      default: "",
      type: [Number, String],
    },
    installed: {
      default: false,
      type: Boolean,
    },
  },
  data: () => ({
    deactivateDialog: false,
    plugin: null,
    pluginConfiguration: {
      viewId: null,
      isGA4: true,
    },
    code: null,
    client: null,
    checkedAuthentication: false,
    isAuthenticated: false,
    hasAnalyticsScope: false,
    accountSummary: null,
    userEmail: null,
  }),
  computed: {
    clientId() {
      return process.env.VUE_APP_GOOGLE_PLUGIN_CLIENT_ID;
    },
  },
  watch: {
    id: {
      immediate: true,
      async handler(val) {
        if (val && this.installed) {
          await this.loadUser();
          await this.loadPlugin(val);
          if (this.isAuthenticated) {
            await this.loadAccountSummary();
          }
        } else {
          this.checkedAuthentication = true;
        }
      },
    },
  },
  methods: {
    async loadUser() {
      try {
        const response = await this.$rest.plugins.googleAnalytics.getUser();
        if (!response.data) {
          return;
        }

        this.checkedAuthentication = true;
        this.isAuthenticated = !!response.data?.email;
        this.userEmail = response.data?.email ?? '';
        if (this.isAuthenticated) {
          await this.loadAccountSummary();
        }
      } catch (error) {
        if (error.response?.status === 400) {
          // A bad request here usually means something's wrong with authentication
          this.checkedAuthentication = true;
          this.isAuthenticated = false;
        }
      }
    },
    async loadPlugin(pluginId) {
      const response = await this.$rest.plugins.get_resource(pluginId);
      if (!response.data) {
        return;
      }

      this.plugin = { ...response.data };
      this.pluginConfiguration.viewId = response.data.pluginConfiguration?.viewId;
      this.pluginConfiguration.isGA4 = response.data.pluginConfiguration?.isGA4;
    },
    async loadAccountSummary() {
      try {
        const response = await this.$rest.plugins.googleAnalytics.getAccountSummary();
        if (!response.data) {
          return;
        }

        this.hasAnalyticsScope = true;
        this.accountSummary = [ ...response.data ];
      } catch (error) {
        if (error.response?.status === 400) {
          // A bad request here usually means something's wrong with permissions
          this.hasAnalyticsScope = false;
        }
      }
    },
    authenticateWithGoogle() {
      this.client = google.accounts.oauth2.initCodeClient({
        client_id: this.clientId,
        scope: "openid email https://www.googleapis.com/auth/analytics.readonly",
        ux_mode: "popup",
        callback: (response) => {
          if (!response?.code) {
            return;
          }

          this.code = response.code;

          this.setAuthCode().then(() => {
            if (!response?.scope.includes('https://www.googleapis.com/auth/analytics.readonly')) {
              this.$store.commit("snackbar/showMessage", {
                content: "Please re-authenticate and allow access to Google Analytics data for this plugin to work successfully.",
                color: "error"
              });

              return;
            }

            this.loadUser();
          });
        },
      });

      this.client.requestCode();
    },
    async setAuthCode() {
      let response = null
      if (!this.plugin) {
        response = await this.$rest.plugins.post_resource({
          plugin: +this.id,
          pluginConfiguration: this.pluginConfiguration,
        });
        if (!response.data) {
          return this.$store.commit("snackbar/showMessage", {
            content: "Failed to install plugin",
            color: "error",
          });
        }

        const selected_account = this.$store.getters["user/account"];
        this.$store.commit("user/update_selected_account", {
          ...selected_account,
          activePlugins: [...selected_account.activePlugins, response.data],
        });
        this.$store.commit("snackbar/showMessage", {
          content: "Installed plugin successfully. Please reload you browser to see google's feature",
          color: "info",
        });
      }

      await this.$rest.plugins.googleAnalytics.setAuthCode({
        code: this.code,
      });

      if (!this.plugin && response) {
        setTimeout(() => {
          this.$router.push({
            name: "AddPluginGoogleAnalytics",
            params: { id: response.data?.id },
            query: { installed: true }
          });
        }, 1000);
      }
    },
    onSelectGoogleProperty(prop) {
      this.pluginConfiguration.viewId = prop ? parseInt(prop.id) : null;
      this.pluginConfiguration.isGA4 = prop ? prop.isGA4 : true;
    },
    async updatePlugin(redirectToReport) {
      const response = await this.$rest.plugins.put_resource(this.id, {
        pluginConfiguration: this.pluginConfiguration,
      });
      if (!response.data) {
        return this.$store.commit("snackbar/showMessage", {
          content: "Failed to update plugin",
          color: "error",
        });
      }

      this.$store.commit("snackbar/showMessage", {
        content: "Plugin updated successfully",
        color: "info",
      });

      if (redirectToReport) {
        setTimeout(() => {
          this.$router.push({
            name: "GoogleAnalytics",
          })
        }, 1000);
      } else {
        setTimeout(() => {
          this.$router.push({
            name: "AddPluginGoogleAnalytics",
            params: { id: response.data?.id },
            query: { installed: true }
          });
        }, 500);
      }
    },
  },
};
</script>
